<template>
  <a
    v-if="item.link && item.link.includes('http')"
    :target="item.new_tab ? '_blank' : '_self'"
    :href="
      item.link
        ? item.link
        : $router.resolve(
            findUrl(item.linkable_type, item.linkable_id, item.slug, item.link)
          ).href
    "
  >
    <slot></slot>
  </a>
  <router-link
    v-else-if="item.link"
    :target="item.new_tab ? '_blank' : '_self'"
    :to="item.link"
  >
    <slot></slot>
  </router-link>
  <router-link
    v-else
    :target="item.new_tab ? '_blank' : '_self'"
    :to="findUrl(item.linkable_type, item.linkable_id, item.slug)"
  >
    <slot></slot>
  </router-link>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";

export default {
  name: "DynamicLinker",
  props: {
    item: Object,
  },
  methods: {
    findUrl,
  },
};
</script>

<style scoped>
a:hover {
  color: var(--color-theme3);
}

a {
  color: inherit;
}
</style>
