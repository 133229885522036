<template>
  <section class="top-header py-3">
    <div
      class="container d-flex justify-content-lg-between align-items-baseline"
    >
<div class="d-flex gap-3 align-items-center">
  <NavButton @click="$emit('openRespMenu')" />
  <AtlasLogo />
  <div
    class="d-inline-block"
    :class="
      notifications && notifications.items && notifications.items.length
        ? 'ml-4'
        : 'ml-1'
    "
    v-if="mediaQueries.mobileSize"
  >
    <CartButton />
    <SearchButton class="bg-none ml-2 d-md-none okokok" />
    <HeaderNotification
      v-if="notifications"
      v-show="notifications.items && notifications.items.length"
      v-model="notifications"
    />
  </div>
  <Nav></Nav>
</div>
      <div class="">

        <button
          type="button"
          class="d-md-inline-block d-none bg-none ml-2"
          @click="showCloseSearchBox()"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="11.7666"
              cy="11.7666"
              r="8.98856"
              stroke="#200E32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></circle>
            <path
              d="M18.0183 18.4851L21.5423 22"
              stroke="#200E32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
        <!-- نمایش اطلاعات کاربری -->

        {{ islogin }}
        <!-- نمایش آیکون اگر لاگین نبود -->

        <button
          v-if="!isLogin"
          type="button"
          class="d-md-inline-block d-none bg-none ml-2"
          data-toggle="modal"
          data-target="#loginModal"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.5 12.5537C12.2546 12.5537 14.4626 10.3171 14.4626 7.52684C14.4626 4.73663 12.2546 2.5 9.5 2.5C6.74543 2.5 4.53737 4.73663 4.53737 7.52684C4.53737 10.3171 6.74543 12.5537 9.5 12.5537ZM9.5 15.0152C5.45422 15.0152 2 15.6621 2 18.2464C2 20.8298 5.4332 21.5 9.5 21.5C13.5448 21.5 17 20.8531 17 18.2687C17 15.6844 13.5668 15.0152 9.5 15.0152ZM19.8979 9.58786H21.101C21.5962 9.58786 22 9.99731 22 10.4995C22 11.0016 21.5962 11.4111 21.101 11.4111H19.8979V12.5884C19.8979 13.0906 19.4952 13.5 18.999 13.5C18.5038 13.5 18.1 13.0906 18.1 12.5884V11.4111H16.899C16.4027 11.4111 16 11.0016 16 10.4995C16 9.99731 16.4027 9.58786 16.899 9.58786H18.1V8.41162C18.1 7.90945 18.5038 7.5 18.999 7.5C19.4952 7.5 19.8979 7.90945 19.8979 8.41162V9.58786Z"
              fill="#200E32"
            ></path>
          </svg>
        </button>
        <div
          class="d-inline-block"
          :class="
            notifications && notifications.items && notifications.items.length
              ? 'ml-4'
              : 'ml-1'
          "
          v-if="!mediaQueries.mobileSize"
        >
          <CartButton />
          <HeaderNotification
            v-if="notifications"
            v-show="
              notifications && notifications.items && notifications.items.length
            "
            v-model="notifications"
          />
        </div>
        <LoginUser
          :class="notifications ? 'mr-3' : 'mr-2'"
          v-if="isLogin"
          :atlasTheme="true"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Nav from "@/parts/Front/components/Nav";
import LoginUser from "@/parts/Front/components/LoginUser";
import AtlasLogo from "@/parts/Front/components/AtlasLogo";
import { cartListsOpen, openSearch } from "@/libraries/functions";
import findUrl from "@/libraries/links/LinkResolver";
import CartButton from "@/parts/Front/components/cart/CartButton";
import HeaderNotification from "@/parts/Front/components/notification/HeaderNotification";
import SearchButton from "./header/SearchButton";
import NavButton from "@/parts/Front/components/shared/NavButton";

export default {
  name: "TopHeader",
  inject: ["mediaQueries"],
  components: {
    NavButton,
    SearchButton,
    HeaderNotification,
    CartButton,
    AtlasLogo,
    LoginUser,
    Nav,
  },
  data() {
    return {
      showOptions: false,
    };
  },
  methods: {
    findUrl,
    // cartListsCloseBody(){
    //   const cartLists=document.getElementsByClassName('cart-lists')[0];
    //   const cartListsCloseResp = document.getElementsByClassName('cart-lists-close-resp')[0];
    //   cartListsCloseResp.style.display="none";
    //   cartLists.style.left="-390px";
    // },
    cartListsOpen,
    showCloseSearchBox() {
      openSearch();
    },
  },
  mounted() {},
  computed: {
    menu() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"].menu.header;
      } else {
        return this.$store.getters["front/getHomeData"];
      }
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    notifications() {
      return this.$store.getters["front/getNotifications"];
    },
  },
};
</script>

<style scoped>
/* افزودن استایل به کدهای اضافه شده */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.childMenu {
  color: #666;
}
#menuSkelton {
  display: flex;
  justify-content: space-around;
  width: 300px;
}
#menuSkelton div {
  width: 50px;
  height: 30px;
}
</style>
