<template>
  <div class="accordion mt-3" id="accordionProductFilter">
    <template v-if="categories != null">
      <div class="card mb-1">
        <div class="card-header d-flex justify-content-between p-0">
          <router-link
            :to="{ name: 'products', query: query }"
            class="fontsize-small pb-1 text-color-666 my-auto"
            :class="!categoryId ? 'category-active' : ''"
          >
            همه دسته بندی ها
          </router-link>
        </div>
      </div>
      <div
        class="card mb-1"
        v-for="(category, index) in categories"
        :key="index"
      >
        <div
          class="card-header d-flex justify-content-between p-0"
          :id="'heading' + category.id"
        >
          <router-link
            :to="{
              name: 'product.category',
              params: { category: category.id, slug: getSlug(category) },
              query: query,
            }"
            class="fontsize-small text-color-666 my-auto py-1"
            :class="category.id == categoryId ? 'category-active' : ''"
          >
            {{ category.title }}
          </router-link>
          <button
            @click="togglePlus"
            :aria-expanded="categoryActive(category)"
            v-if="category.children != ''"
            class="all-categories-side-btn collapsed px-3 py-0"
            type="button"
            data-toggle="collapse"
            :data-target="'#collapse' + category.id"
            :aria-controls="'collapse' + category.id"
          >
            <span class="openclose fontsize-heavy text-color-666">{{
              categoryActive(category) ? "-" : "+"
            }}</span>
          </button>
        </div>
        <div
          v-if="category.children != []"
          :id="'collapse' + category.id"
          :class="categoryActive(category) ? 'collapse show' : 'collapse'"
          :aria-labelledby="'heading' + category.id"
          data-parent="#accordionProductFilter"
        >
          <div class="card-body py-0">
            <ul class="list-unstyled fontsize-small mb-0">
              <li v-for="item in category.children" :key="item.id">
                <div
                  class="card-header d-flex justify-content-between p-0"
                  :id="'heading-category' + item.id"
                >
                  <router-link
                    class="px-2 py-1"
                    :class="item.id == categoryId ? 'category-active' : ''"
                    :to="{
                      name: 'product.category',
                      params: { category: item.id, slug: getSlug(item) },
                      query: query,
                    }"
                  >
                    {{ item.title }}
                  </router-link>
                  <button
                    @click="togglePlus"
                    :aria-expanded="categoryActive(item)"
                    v-if="item.children != ''"
                    class="all-categories-side-btn collapsed px-3 py-1"
                    type="button"
                    data-toggle="collapse"
                    :data-target="'#collapse-category' + item.id"
                    :aria-controls="'collapse-category' + item.id"
                  >
                    <span class="openclose fontsize-medium text-color-666">{{
                      categoryActive(item) ? "-" : "+"
                    }}</span>
                  </button>
                </div>
                <div
                  v-if="category.children != []"
                  :id="'collapse-category' + item.id"
                  :class="categoryActive(item) ? 'collapse show' : 'collapse'"
                  :aria-labelledby="'heading-category' + item.id"
                >
                  <div class="card-body pt-0 pb-1">
                    <ul class="list-unstyled fontsize13 mb-0">
                      <li v-for="subItem in item.children" :key="subItem.id">
                        <div
                          class="card-header d-flex justify-content-between p-0"
                          :id="'heading-category' + subItem.id"
                        >
                          <router-link
                            class="px-2"
                            :class="
                              subItem.id == categoryId ? 'category-active' : ''
                            "
                            :to="{
                              name: 'product.category',
                              params: {
                                category: subItem.id,
                                slug: getSlug(subItem),
                              },
                              query: query,
                            }"
                          >
                            {{ subItem.title }}
                          </router-link>
                          <button
                            @click="togglePlus"
                            :aria-expanded="categoryActive(subItem)"
                            v-if="subItem.children != ''"
                            class="all-categories-side-btn collapsed px-3 py-1"
                            type="button"
                            data-toggle="collapse"
                            :data-target="'#collapse-category' + subItem.id"
                            :aria-controls="'collapse-category' + subItem.id"
                          >
                            <span
                              class="openclose fontsize-medium text-color-666"
                              >{{ categoryActive(subItem) ? "-" : "+" }}</span
                            >
                          </button>
                        </div>
                        <div
                          v-if="subItem.children != []"
                          :id="'collapse-category' + subItem.id"
                          :class="
                            categoryActive(subItem)
                              ? 'collapse show'
                              : 'collapse'
                          "
                          :aria-labelledby="'heading-category' + subItem.id"
                        >
                          <div class="card-body pt-0 pb-1">
                            <ul class="list-unstyled fontsize13 mb-0">
                              <li
                                v-for="subSubItem in subItem.children"
                                :key="subSubItem.id"
                              >
                                <router-link
                                  class="px-2"
                                  :class="
                                    subSubItem.id == categoryId
                                      ? 'category-active'
                                      : ''
                                  "
                                  :to="{
                                    name: 'product.category',
                                    params: {
                                      category: subSubItem.id,
                                      slug: subSubItem.slug,
                                    },
                                    query: query,
                                  }"
                                >
                                  {{ subSubItem.title }}
                                </router-link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <b-skeleton class="mx-4"></b-skeleton>
      <b-skeleton class="mx-4"></b-skeleton>
      <b-skeleton class="mx-4"></b-skeleton>
    </template>
  </div>
</template>
<script>
import { BSkeleton } from "bootstrap-vue";

export default {
  components: {
    BSkeleton,
  },
  props: {
    categoryId: [Number, String],
    categories: Array,
    query: {
      default: () => {},
      type: Object,
    },
  },
  methods: {
    getSlug(item) {
      let Text = item.slug ? item.slug : item.title;
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    categoryActive(category) {
      let flag = false;
      if (category.id == this.categoryId && category.children.length) {
        flag = true;
      }
      category.children.forEach((childCategory) => {
        if (childCategory.id == this.categoryId) {
          flag = true;
        }
        childCategory.children.forEach((childChildCategory) => {
          if (childChildCategory.id == this.categoryId) {
            flag = true;
          }
          childChildCategory.children.forEach((childChildChildCategory) => {
            if (childChildChildCategory.id == this.categoryId) {
              flag = true;
            }
          });
        });
      });
      return flag;
    },
    togglePlus(e) {
      let el = e.currentTarget;
      el.children.forEach((child) => {
        window.child = child;
        if (child.classList.contains("openclose")) {
          if (child.innerHTML === "+") {
            child.innerHTML = "-";
          } else {
            child.innerHTML = "+";
          }
        }
      });
    },
  },
};
</script>
<style>
.products-list-filter .card-header > .category-active::before,
.products-list-filter .card-body .category-active::before {
  background-color: #713348;
}

.products-list-filter .card-header > .category-active,
.products-list-filter .card-body .category-active {
  color: #c92f2f;
}
</style>
