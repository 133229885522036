<template>
    <button
      :id="atlasTheme==true?'loginIcon1':'loginIcon'"
      @click.prevent="showOptions = !showOptions"
      class="logged-in-btn d-md-inline-block d-none newBGColor ml-2 pl-2 mr-3 text-white">
      <div  class="d-inline-block py-2 pr-2 pl-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="#ffffff"
          class="bi bi-person"
          viewBox="0 0 16 16"
        >
          <path
            fill="#ffffff"
            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
          />
        </svg>
      </div>
      <span
        v-if="FullName != null"
        class="fontsize-small text-white mx-1"
      >
        {{ FullName }}</span
      >
      <span v-else class="fontsize13 text-white mx-1">حساب کاربری</span>
      <svg
        width="13"
        height="11"
        viewBox="0 0 24 24"
        fill="#ffffff"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.869 16.6308C10.811 16.5743 10.563 16.3609 10.359 16.1622C9.076 14.9971 6.976 11.9576 6.335 10.3668C6.232 10.1252 6.014 9.51437 6 9.18802C6 8.8753 6.072 8.5772 6.218 8.29274C6.422 7.93814 6.743 7.65368 7.122 7.49781C7.385 7.39747 8.172 7.2416 8.186 7.2416C9.047 7.08573 10.446 7 11.992 7C13.465 7 14.807 7.08573 15.681 7.21335C15.695 7.22796 16.673 7.38383 17.008 7.55431C17.62 7.86702 18 8.47784 18 9.13151V9.18802C17.985 9.61374 17.605 10.509 17.591 10.509C16.949 12.0141 14.952 14.9834 13.625 16.1768C13.625 16.1768 13.284 16.5129 13.071 16.659C12.765 16.887 12.386 17 12.007 17C11.584 17 11.19 16.8724 10.869 16.6308Z"
          fill="#ffffff"
        />
      </svg>
      <transition name="fade">
        <div v-if="showOptions" id="loginUserOptions">
          <router-link
          to="/user-panel"
            @click="gotoUserPanel()"
            :class="{ disabledLogout: disabledlLogout }"
            >مشاهده حساب کاربری</router-link
          >
          <button
            class="logout-btn"
            @click="signout()"
            :class="{ disabledLogout: disabledlLogout }"
          >
            خروج <i class="fa fa-signout"></i>
          </button>
        </div>
      </transition>
    </button>
</template>
<script>
export default {
    props:{
atlasTheme:{Boolean}
    },
    data(){
        return{
            showOptions:false,disabledlLogout:false
        }
    },
    methods:{
        gotoUserPanel(){
            this.showOptions=false;
        },
    signout() {
        window
        .swal({
          title: "آیا میخواهید خارج شوید؟",

          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله خارج شو"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.disabledlLogout=true;
            this.$axios.post("/customer/logout").then((res) => {
              console.log('okokokko')
              this.disabledlLogout=false;
              this.showOptions = false;
              // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
              this.$root.deleteCookie("Authorization");
              delete this.$axios.defaults.headers.common["Authorization"]
              this.$store.commit("front/setLoginStatus", false);
              location.reload();
            });
          }
        });
    },
    },
    computed:{

    // دریافت اطلاعات کاربر
    FullName() {
      return this.$store.getters["front/getFullName"];
    },
    }
};
</script>
<style scoped>
    #loginIcon.logged-in-btn div {
    /* background-color: var(--color-theme4); */
      /*border-radius: 0 0 10px 10px;*/
    -webkit-clip-path: none;
    clip-path: none
}


#loginUserOptions {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 10;
}
#loginUserOptions button,
#loginUserOptions a {
  padding: 10px;
  background-color: var(--color-theme4);
  color: #ffffff !important;
  transition: all 1s;
}
#loginUserOptions button:hover,
#loginUserOptions a:hover {
  background-color: var(--color-theme);
  color: white !important;
}

@media (max-width: 768px) {
  #loginUserOptions {
    display: none;
  }
}
.disabledLogout{
pointer-events: none;
opacity: 0.5;
}
#showOptionBtn{
  position: relative;
}
#loginIcon1.logged-in-btn div {
    -webkit-clip-path: none;
    clip-path: none;
}
a{
    font-size:14px;
}

.bi-person {
  transform: translateY(-2px);
}

.logout-btn {
  border-radius: 0 0 10px 10px;
}

.logged-in-btn div {
  /* background-color: var(--color-theme4); */
  clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
}
.logged-in-btn {
  position: relative;
  /* background-color: var(--color-theme4); */
  min-width: 137px;
}

</style>
