<template>
  <header class="">
    
    <section
      class="
        container
        d-flex
        justify-content-between
        align-items-baseline
        py-3
      "
    >
   
      <NavButton @click="allCategoriesClose()" />
      <div class="d-flex align-items-center" style="gap: 30px">
        <AtlasLogo />
        <Nav></Nav>
      </div>
      <div class="">
        <SearchButton class="d-md-inline-block d-none bg-none ml-2" />
        <!-- اگر لاگین نبود ایکن ورود نمایش داده شود -->
        <button
          v-if="!isLogin"
          type="button"
          class="d-md-inline-block d-none bg-none ml-2"
          data-toggle="modal"
          data-target="#loginModal"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.5 12.5537C12.2546 12.5537 14.4626 10.3171 14.4626 7.52684C14.4626 4.73663 12.2546 2.5 9.5 2.5C6.74543 2.5 4.53737 4.73663 4.53737 7.52684C4.53737 10.3171 6.74543 12.5537 9.5 12.5537ZM9.5 15.0152C5.45422 15.0152 2 15.6621 2 18.2464C2 20.8298 5.4332 21.5 9.5 21.5C13.5448 21.5 17 20.8531 17 18.2687C17 15.6844 13.5668 15.0152 9.5 15.0152ZM19.8979 9.58786H21.101C21.5962 9.58786 22 9.99731 22 10.4995C22 11.0016 21.5962 11.4111 21.101 11.4111H19.8979V12.5884C19.8979 13.0906 19.4952 13.5 18.999 13.5C18.5038 13.5 18.1 13.0906 18.1 12.5884V11.4111H16.899C16.4027 11.4111 16 11.0016 16 10.4995C16 9.99731 16.4027 9.58786 16.899 9.58786H18.1V8.41162C18.1 7.90945 18.5038 7.5 18.999 7.5C19.4952 7.5 19.8979 7.90945 19.8979 8.41162V9.58786Z"
              fill="#200E32"
            ></path>
          </svg>
        </button>
        <div
          class="d-inline-block"
          :class="
            notifications && notifications.items && notifications.items.length
              ? 'ml-4'
              : 'ml-1'
          "
        >
          <CartButton />

          <HeaderNotification
            v-if="notifications"
            v-show="
              notifications && notifications.items && notifications.items.length
            "
            :value="notifications"
          />
          <!-- لاگین بود نمایش حساب کاربری -->
          <SearchButton class="bg-none ml-2 d-md-none" />
        </div>
        <template v-if="isLogin">
          <LoginUser :atlasTheme="false" />
        </template>
      </div>
    </section>
    <MainSearch></MainSearch>
    <!-- resp login Modal -->
    <RespLoginModal></RespLoginModal>
    <CartLists></CartLists>
    <b-skeleton-img v-if="!homeData"></b-skeleton-img>
    <section v-else class="top-slider position-relative mb-3">
      <div class="swiper-container h-100">
        <div v-if="homeData" class="swiper-wrapper">
          <div
            v-for="slider in sliders"
            :key="slider.id"
            class="swiper-slide bg-color-ea slide-aspect-ratio"
          >
            <DynamicLinker class="top-slider-img" :item="slider">
              <img
                :src="slider.image.url"
                class="w-100 h-100"
                :alt="slider.title"
              />
            </DynamicLinker>
          </div>
        </div>
      </div>
    </section>
    <RespMenu ref="respMenu"></RespMenu>
  </header>
</template>

<script>
import AtlasLogo from "@/parts/Front/components/AtlasLogo";
import Swiper from "swiper/swiper-bundle.min.js";
import Nav from "@/parts/Front/components/Nav";
import ProductCategories from "@/parts/Front/components/ProductCategories";
import RespMenu from "@/parts/Front/components/RespMenu";
import MainSearch from "@/parts/Front/components/MainSearch";
import LoginUser from "@/parts/Front/components/LoginUser";
import RespLoginModal from "@/parts/Front/components/RespLoginModal";
import CartLists from "@/parts/Front/components/CartLists";
import findUrl from "@/libraries/links/LinkResolver";
import HeaderNotification from "@/parts/Front/components/notification/HeaderNotification";
import CartButton from "@/parts/Front/components/cart/CartButton";
import DynamicLinker from "./DynamicLinker";
import SearchButton from "../header/SearchButton";
import { BSkeletonImg } from "bootstrap-vue";
import NavButton from "@/parts/Front/components/shared/NavButton";
export default {
  name: "Header",
  components: {
    NavButton,
    SearchButton,
    DynamicLinker,
    AtlasLogo,
    HeaderNotification,
    Nav,
    LoginUser,
    ProductCategories,
    RespMenu,
    MainSearch,
    RespLoginModal,
    CartLists,
    CartButton,
    BSkeletonImg,
  },
  watch: {
    homeData() {
      // پاک کردن سویپر برای نمایش درست اسلایدر
      this.initHeaderSwiper(true);
    },
  },
  inject: ["mediaQueries"],
  methods: {
    findUrl,
    getSliderCustomFields(slider) {
      return JSON.parse(slider.custom_fields);
    },
    allCategoriesClose() {
      this.$refs.respMenu.allCategoriesToggle();
    },
    initHeaderSwiper(kill = false) {
      this.initSwiper("header-swiper", kill, () => {
        try {
          if (this.slider) {
            this.slider.destroy();
          }
        } catch (e) {}
        this.slider = new Swiper(".top-slider .swiper-container", {
          spaceBetween: 0,
          loop: false,
          autoplay: false,
        });
      });
    },
    signout() {
      window
        .swal({
          title: "آیا میخواهید خارج شوید؟",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله خارج شو"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.disabledlLogout = true;
            this.$axios.post("/customer/logout").then((res) => {
              this.disabledlLogout = false;
              this.showOptions = false;
              // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
              delete this.$axios.defaults.headers.common["Authorization"];
              this.$root.deleteCookie("Authorization");
              this.$store.commit("front/setLoginStatus", false);
            });
          }
        });
    },
  },
  mounted() {
    this.initHeaderSwiper();
  },
  computed: {
    // دریافت دیتا از سمت ویوایکس
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    sliders() {
      let sliders;
      if (this.mediaQueries.medium) {
        sliders = this.homeData.sliders.filter((s) =>
          s.group.includes("mobile")
        );
      } else {
        sliders = this.homeData.sliders.filter(
          (s) => !s.group.includes("mobile")
        );
      }
      sliders.forEach((slider) => {
        try {
          slider.custom_fields = this.getSliderCustomFields(slider);
        } catch (e) {}
      });
      return sliders;
    },
    // چک کردن لاگین بودن کاربر با استفاده از ویو ایکس در سند یوزر
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
    // دریافت اطلاعات کاربر
    FullName() {
      return this.$store.getters["front/getFullName"];
    },
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    notifications() {
      return this.$store.getters["front/getNotifications"];
    },
  },
  data() {
    return {
      disabledlLogout: false,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-aspect-ratio {
  aspect-ratio: 2.6/1;
}
@media screen and (max-width: 768px) {
  .slide-aspect-ratio {
    aspect-ratio: 2.5/1;
  }
}
</style>
