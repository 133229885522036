<template>
  <div
    id="comments"
    class="tab-pane fade pb-4 in-comments"
    :class="[activeTab === 'comments' ? 'in active show' : '']"
  >

    <div class="d-sm-flex justify-content-center pt-3 px-3  pb-4 ">
      <div class=" justify-content-around mb-0">
        <h6
          v-if="comments != null && comments.length != 0"
          class="text-color-444 mb-4 fontsize-medium"
        >
          دیدگاه خود را درباره این کالا بیان کنید.
        </h6>
        <span v-if="comments != null && comments.length == 0">
          <h6
            class="fontsize-small text-color-666 text-center line-height2 mb-2 "
          >
            دیدگاهی برای این محصول ثبت نشده است.
          </h6>
          <h5
            class="fontsize-medium text-color-444 text-center line-height2 mb-4 font-weight-bold text-center"
          >
            اولین نفری باشید که دیدگاهی را ارسال می‌کند.
          </h5>
        </span>
        <button
          type="button"
          data-toggle="collapse"
          data-target="#collapseComment"
          aria-expanded="false"
          aria-controls="collapseComment"
          class="send-comment-btn fontsize-small text-white py-2 px-5 bg-color-theme d-block mx-auto"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 12.015C2 6.74712 6.21 2 12.02 2C17.7 2 22 6.65699 22 11.985C22 18.1642 16.96 22 12 22C10.36 22 8.54 21.5593 7.08 20.698C6.57 20.3876 6.14 20.1572 5.59 20.3375L3.57 20.9384C3.06 21.0986 2.6 20.698 2.75 20.1572L3.42 17.9139C3.53 17.6034 3.51 17.2729 3.35 17.0125C2.49 15.4301 2 13.6975 2 12.015ZM10.7 12.015C10.7 12.7261 11.27 13.2969 11.98 13.307C12.69 13.307 13.26 12.7261 13.26 12.025C13.26 11.314 12.69 10.7431 11.98 10.7431C11.28 10.7331 10.7 11.314 10.7 12.015ZM15.31 12.025C15.31 12.7261 15.88 13.307 16.59 13.307C17.3 13.307 17.87 12.7261 17.87 12.025C17.87 11.314 17.3 10.7431 16.59 10.7431C15.88 10.7431 15.31 11.314 15.31 12.025ZM7.37 13.307C6.67 13.307 6.09 12.7261 6.09 12.025C6.09 11.314 6.66 10.7431 7.37 10.7431C8.08 10.7431 8.65 11.314 8.65 12.025C8.65 12.7261 8.08 13.2969 7.37 13.307Z"
              fill="currentColor"
            />
          </svg>
          برای ارسال نظر کلیک کنید
        </button>
      </div>
    </div>
    <section
      class="response-register collapse box-shaddow20   mb-3"
      id="collapseComment"
    >
      <!-- <h6 class="fontsize-small">پاسخی بگذارید</h6> -->
      <!-- <p class="fontsize-mini text-color-666">
        نشانی ایمیل شما منتشر نخواهد شد و بخش های موردنیاز علامت گذاری شده اند.
      </p> -->
      <form class="fontsize13 p-4" @submit.prevent="commentSubmit()">
        <div class="response-register-grid">
          <div class="d-flex flex-column fontsize-mini">
            <input
              class="bg-color-eai rounded p-2  mb-2 w-100"
              type="text"
              v-model="comment.title"
              placeholder="عنوان"
            />
            <!-- <input class="bg-color-eai rounded p-2  mb-2" type="text" v-model="comment.name"
                placeholder="نام کامل ..."> -->
            <!-- <input class="bg-color-eai rounded p-2 mb-2" type="email" v-model="comment.email"
                placeholder=" آدرس ایمیل ..."> -->
            <!-- <input class="bg-color-eai rounded p-2 mb-2" type="text" placeholder=" آدرس وب سایت..."> -->
            <b-form-rating
              v-model="comment.rate"
              variant="warning"
              class="border-0 pb-0 pt-3 py-sm-2 pr-sm-3 pr-0"
              size="lg"
            >
            </b-form-rating>
            <b-form-checkbox
              class="pt-3 pr-sm-4 pb-sm-0 pb-3"
              :disabled="disabled"
              v-model="comment.show_customer_name"
              value="1"
              unchecked-value="0"
            >
              <span class="pr-4">نمایش نام شما</span>
            </b-form-checkbox>
          </div>
          <div>
            <textarea
              class="bg-color-eai rounded p-2 mb-2 w-100 fontsize-mini"
              v-model="comment.body"
              name=""
              cols="5"
              rows="5"
              placeholder="دیدگاه شما *"
            ></textarea>
          </div>
        </div>

        <div class="text-left">
          <button
            v-if="!isLogin"
            data-toggle="modal"
            data-target="#loginModal"
            type="submit"
            class="py-2 px-md-5 px-3 bg-color-theme text-black-white fontsize-small "
          >
            ارسال پیام
          </button>
          <button
            v-else
            type="submit"
            class="py-2 px-md-5 px-3 bg-color-theme text-black-white fontsize-small "
          >
            ارسال پیام
          </button>
        </div>
      </form>
    </section>
    <!-- <Loading v-if="comments == null" /> -->
    <div v-if="comments" class="user-comments px-sm-3 px-2 py-sm-2 pt-4">
      <div
        v-for="(comment, index) in comments"
        :key="comment.id"
        :class="index == 0 ? 'border-top' : ''"
        class="user-comments-item pb-3 mb-3 border-bottom"
      >
        <!-- user Comment -->
        <div>
          <div
            class="d-flex flex-wrap align-items-baseline justify-content-between mb-2"
          >
            <span>
              <span
                v-if="
                  comment.creator &&
                    (comment.creator.first_name || comment.creator.last_name)
                "
                class="fontsize-small weight-bold text-color-444"
              >
                {{
                  (comment.creator.first_name || "") +
                    " " +
                    (comment.creator.last_name || "")
                }}
              </span>
              <span v-else>ناشناس</span>
              <span class="text-color-999 fontsize-mini mr-2">
                {{ moment(comment.created_at).format("dddd DD MMMM YYYY") }}
              </span>
            </span>
            <span>
              <b-form-rating
                v-model="comment.rate"
                variant="warning"
                :readonly="true"
                class="border-0 pb-0 pt-3"
                size="lg"
              >
              </b-form-rating>
            </span>
          </div>
          <h6
            class="fontsize-medium text-justify line-height2"
            style="color: #555;"
          >
            {{ comment.title }}
          </h6>
          <p class="text-color-666 fontsize-small text-justify line-height2">
            {{ comment.body }}
          </p>
        </div>
        <!-- admin Answer -->
         <template v-if="comment.children && comment.children.length " >
          <div v-for="item in comment.children"  class="d-flex align-items-center gap-2 p-2">
          <div class="mx-5 p-3 svgAnswer">
            
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m6.921 15.5l3.433 3.433q.146.146.159.344q.012.198-.153.363q-.166.166-.357.166t-.357-.166l-4.08-4.075q-.131-.13-.184-.267T5.329 15t.053-.298t.184-.268l4.086-4.086q.146-.146.344-.153t.364.159q.16.165.162.354t-.162.353L6.92 14.5h9.464q.269 0 .442-.173t.173-.442V5.5q0-.213.143-.357T17.5 5t.357.143T18 5.5v8.385q0 .67-.472 1.143q-.472.472-1.143.472z"
              />
            </svg>
          </div>
          <div>
            <div
              class="d-flex flex-wrap align-items-baseline justify-content-between mb-2"
            >
              <span>
                <span
                  v-if="
                    item.creator &&
                      (item.creator.first_name || item.creator.last_name)
                  "
                  class="fontsize-small weight-bold text-color-444"
                >
                  {{
                    (item.creator.first_name || "") +
                      " " +
                      (item.creator.last_name || "")
                  }}
                </span>
                <span v-else>ادمین</span>
                <span class="text-color-999 fontsize-mini mr-2">
                  {{ moment(item.created_at).format("dddd DD MMMM YYYY") }}
                </span>
              </span>
            </div>
            <h6
              class="fontsize-medium text-justify line-height2"
              style="color: #555;"
            >
              {{ item.title }}
            </h6>
            <p
              class="text-color-666 fontsize-small text-justify line-height2 m-0"
            >
              {{ item.body }}
            </p>
          </div>
        </div>
         </template>
       
      </div>
    </div>

    <!-- <div class="text-center d-sm-none">
      <button type="button" class="fontsize13 text-color-999 bg-none">
        اطلاعات بیشتر ...
      </button>
    </div> -->
  </div>
</template>
<script>
import { BFormRating, BFormCheckbox } from "bootstrap-vue";

import Loading from "@/parts/Front/components/shared/Loading";
export default {
  props: ["activeTab", "product"],
  components: {
    Loading,
    BFormRating,
    BFormCheckbox,
  },
  data() {
    return {
      comment: {
        rate: 4,
        title: "",
        body: "",
        show_customer_name: 1,
      },
      disabled: false,
      comments: null,
      getCommentsStatus: false,
    };
  },
  watch: {
    activeTab(newActiveTab) {
      if (newActiveTab === "comments") {
        this.getComments();
      }
    },
  },
  mounted() {
    if (this.activeTab === "comments") {
      this.getComments();
    }
  },
  methods: {
    getComments() {
      if (this.comments == null && !this.getCommentsStatus) {
        this.getCommentsStatus = true;
        this.$axios
          .get(`front/product-comment/${this.$route.params.id}`)
          .then((response) => {
            this.comments = response.data.data.comments;
          });
      }
    },
    async commentSubmit() {
      if (this.$store.getters["front/getLoginStatus"]) {
        this.disabled = true;
        try {
          const response = await this.$axios.post("customer/product-comment", {
            rate: this.comment.rate,
            title: this.comment.title,
            body: this.comment.body,
            product_id: this.$route.params.id,
            show_customer_name: this.comment.show_customer_name,
          });

          this.$root.notify(response.data.message, "success");
          this.comment.body = "";
          this.comment.title = "";
        } catch (error) {
          this.$root.notify(error);
        } finally {
          this.disabled = false;
        }
      } else {
        this.$root.notify("برای ثبت نظر ابتدا وارد سایت شوید.", "warning");
      }
    },
  },
  computed: {
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  },
};
</script>
<style scoped>
.response-register form textarea {
  height: unset !important;
}
</style>
<style>
#comments .form-control:focus {
  box-shadow: unset !important;
}

#comments .form-control-lg {
  height: unset !important;
}

#comments .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--color-theme);
  background-color: var(--color-theme);
}

#comments
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: var(--color-theme) !important;
}

#comments .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgb(183, 149, 111, 0.5);
}

#comments .custom-control-label::before {
  top: 0;
}

#comments .custom-control-label::after {
  top: 0;
}

#comments .flex-grow-1 {
  flex-grow: unset !important;
}
.svgAnswer {
  background-color: #5555552d;
}

@media screen and (max-width: 768px) {
  .send-comment-btn {
    display: block;
    margin: auto;
  }
}
</style>
