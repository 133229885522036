<template>
  <section
    v-if="products && products.length"
    class="pt-4 mt-2 mt-sm-4 mb-sm-5 mb-4 "
  >
    <BigTitle v-if="title" class="mb-2 mb-sm-4" :title="title" />
    <div class="special-items" :style="`min-height: ${minHeight}px`">
      <section class="recommended-products container ">
        <!-- <div
          class="d-flex flex-wrap justify-content-center align-items-baseline"
        >
          <div class="recommended-products-category d-flex">
            <ul class="nav nav-tabs">
              <li
                v-for="(tab, index) in tabs"
                :key="index"
                @click="() => (activeTab = tab.name)"
                @mouseenter="loadTab(tab.name)"
                v-if="hasAny(tab.name)"
                class="nav-item ml-1 "
                role="presentation"
              >
                <a
                  class="nav-link py-1 px-md-3 px-2 fontsize-large fontsize-md-17 bg-none"
                  :class="{ active: activeTab == tab.name }"
                  id="home-tab"
                  aria-controls="home"
                  aria-selected="true"
                  >{{ tab.label }}</a
                >
              </li>
            </ul>
          </div>
        </div> -->
        <div class="recommended-content pt-4">
          <div class="recommended-content-item position-relative" id="home">
            <div :style="`min-height: ${minHeight}px`">
              <div
                class="swiper-container"
                v-if="!disabledSliders.includes('recommendedProductSlider')"
              >
                <div class="swiper-wrapper">
                  <Product
                    class="swiper-slide"
                    :style="'max-width:' + maxWidth + 'px'"
                    v-for="(product, index) in products"
                    :key="index"
                    :product="product"
                  />
                </div>
              </div>
            </div>
            <div class="swiper-button-next">
              <ZArrowBold style="transform: rotate(180deg)" />
            </div>
            <div class="swiper-button-prev">
              <ZArrowBold />
            </div>
          </div>
        </div>
      </section>
      <div class="text-center">
          <MoreButton :to="link"/>
        </div>
    </div>
  </section>
</template>

<script>

import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import debounce from "lodash/debounce";
import { getImage } from "@/libraries/functions";
import ZArrowBold from "@/parts/Front/components/shared/ZArrowBold";
import MoreButton from "./shared/MoreButton.vue";
import BigTitle from "@/parts/Front/nicecomponents/shared/BigTitle";

let breakpoints = {
  300: {
    slidesPerView: 2,
    spaceBetween: 4,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 1,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 1,
  },
  1200: {
    slidesPerView: 5,
    spaceBetween: 10,
  },
};

export default {
  name: "RecommendedProductSlider",
  components: { ZArrowBold, Product, MoreButton, BigTitle },
  data() {
    return {
      minHeight: "unset",
      maxWidth: "unset",
      activeTab: "new_products",
      tabs: [
        // {
        //   name: "vip_unpublished_products",
        //   label: "محصولات VIP",
        // }
      ],
      loadedTabs: ["new_products"],
      sliderIsLoading: true,
      onResizeFunction: debounce(this.onResize, 150),
    };
  },
  props:{
    title:String,
    products:Array,
    link:String
  },
  watch: {
    activeTab: {
      handler: function() {
        this.initSlider(true);
      },
    },
    products() {
      if (!this[this.activeTab] || !this[this.activeTab].length) {
        this.activeTab = "suggestions";
      }
      this.initSlider(true);
    },
  },
  mounted() {
    this.initSlider(false);
    this.setMaxWidth();
    window.addEventListener("resize", this.onResizeFunction);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeFunction);
  },
  computed: {
    showCount() {
      return 16;
    },
    
   
  },
  methods: {
    hasAny(name) {
      return this[name] && this[name].length;
    },
    getProductMainImageUrl(product) {
      return getImage(product.major_image, "lg");
    },
    onResize() {
      this.setMaxWidth();
    },
    loadTab(name) {
      if (this.loadedTabs.includes(name)) {
        return;
      }
      this.loadedTabs.push(name);
      this[name].forEach((product) => {
        if (!product) return;
        let url = this.getProductMainImageUrl(product);
        let img = new Image();
        img.src = url;
      });
    },
    setMaxWidth() {
      let slidesPerView = this.getSlidesPerView();
      let spaceBetween = slidesPerView.spaceBetween;
      slidesPerView = slidesPerView.slidesPerView;
      this.maxWidth =
        (document.querySelector(".recommended-products").getBoundingClientRect()
          .width -
          spaceBetween * slidesPerView) /
          slidesPerView +
        (window.innerWidth > 768 ? 2 : 0);
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
    initSlider(kill = false) {
      this.initSwiper("recommendedProductSlider", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        if (document.querySelector(".recommended-products") == null) return;
        this.slider = new Swiper(".recommended-products .swiper-container", {
          spaceBetween: 1,
          //   centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".recommended-products .swiper-button-next",
            prevEl: ".recommended-products .swiper-button-prev",
          },
          breakpoints,
        });
        setTimeout(() => {
          this.minHeight = document
            .querySelector(".recommended-products .swiper-container")
            .getBoundingClientRect().height;
        }, 1700);
      });
    },
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
    
  },
};
</script>

<style scoped>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.swiper-container {
  animation: fade 450ms;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000000;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
.swiper-button-prev,
.swiper-button-next {
  background: var(--color-theme);
  height: 38px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-prev {
  right: -10px;
  transform: translateY(-40px);
}
.swiper-button-next {
  left: -10px;
  right: unset;
  transform: translateY(-40px);
}

.nav-link {
  color: #696969 !important;
}
.nav-link:hover {
  color: white !important;
}
</style>
<style>
.nav-link.active {
  color: black !important;
  font-weight: bold;
  font-size: 1.1rem !important;
  position: relative;
}

.nav-link.active:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  width: 50%;
  height: 2px;
  background: var(--color-theme);
  opacity: 0.7;
}

.recommended-products-more {
  background: var(--color-theme2);
}

.recommended-products-more:hover {
  background: #000;
  color: white;
}
.special-items {
  margin-top: 32px;
}
.special-items-header h3 {
  font-size: 12px;
  color: #ababab;
}
.special-items-header h2 {
  font-size: 25px;
  color: #313131;
}
.special-items {
  /* background: url("../../../assets/images/special-items-bck.jpg") center/cover
    no-repeat; */
  /* height: 430px; */
  position: relative;
  /* background: linear-gradient(32deg, #fbc8b3, transparent,#fbc8b3); */
}

.special-items .special-items-header {
  background: url("../../../assets/images/special-items-bck2.png") center/40%
    60% no-repeat;
  position: absolute;
  top: -88px;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .special-items .special-items-header {
    background: url("../../../assets/images/special-items-bck2.png") center/60%
      60% no-repeat;
  }
}

@media only screen and (max-width: 600px) {
  .special-items .special-items-header {
    background: url("../../../assets/images/special-items-bck2.png") center/90%
      60% no-repeat;
  }
}

@media only screen and (max-width: 400px) {
  .special-items .special-items-header {
    background: url("../../../assets/images/special-items-bck2.png") center/100%
      60% no-repeat;
  }
}
</style>
