<template>
  <section class="d-block d-md-none">
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="gap: 15px;"
    >
      <article v-for="(category, index) in special" :key="index">
        <router-link
          :to="{
            name: 'product.category',
            params: { category: category.link },
          }"
        >
          <div class="w-100">
            <img class="w-100" :src="category.url" :alt="category.title" />
          </div>
        </router-link>
      </article>
    </div>
  </section>
</template>

<script>
import img1 from "../../../assets/images/aftab.webp";
import img2 from "../../../assets/images/joosh.webp";
import img3 from "../../../assets/images/bodycare.webp";
import img4 from "../../../assets/images/lak.webp";
import img5 from "../../../assets/images/mask.webp";
export default {
  data() {
    return {
      special: [
        {
          id: 1,
          title: "ضد آفتاب",
          url: img1,
          link: "191",
        },
        {
          id: 2,
          title: "ضد جوش",
          url: img2,
          link: "139",
        },
        {
          id: 3,
          title: "مراقبت بدن",
          url: img3,
          link: "81",
        },
        {
          id: 4,
          title: "ضد لک",
          url: img4,
          link: "142",
        },
        {
          id: 5,
          title: "ماسک",
          url: img5,
          link: "187",
        },
      ],
    };
  },
  computed: {
    // special_categories() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return null;
    //   } else {
    //     return this.$store.getters["front/getHomeData"].special_categories;
    //   }
    // },
  },
};
</script>

<style></style>
