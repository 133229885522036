<template>
  <div>
    <!--resp menu -->
    <section class="all-categories-side bgWhite pt-3 text-right">
      <div class="d-flex align-items-baseline justify-content-between">
        <router-link to="/" class="mr-3"><img class="menu-logo" style="max-width: 160px;width: 100px" src="@/assets/images/logo.png" alt="angelbar"></router-link>
        <button type="button" class="bg-none weight-bold ml-3 mt-2 pt-1 px-2 text-color-666 rounded" @click="allCategoriesToggle()">
          <CloseSvg width="30"/>
        </button>


      </div>
      <div class="accordion pb-3 border-radius15" id="accordionExample">
        <div class="accordion mt-3 py-3 pt-0 border-radius15" id="accordionExample">
          <div v-show="category!=null" class="card">
            <div data-toggle="collapse"
                 data-target="#allCategory"
                 aria-expanded="false"
                 aria-controls="collapseFour"
                 class="card-header my-1 pr-3 d-flex justify-content-between collapsed"
                 id="headingFour"
                 @click="rotateArrow($refs.moreCategories)"
            >
            <span class="font-weight-bold text-color-444 my-auto"
            >همه دسته بندی ها</span
            >
              <button ref="moreCategories" class="all-categories-side-btn" type="button">
                <svg
                    class="svg1"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      stroke="#444"
                      stroke-width="3"
                      d="M16.2559 4.24279C16.5805 4.56431 16.5827 5.08555 16.2537 5.40707L11.3882 10.1717L11.3023 10.2451C10.9734 10.4897 10.5033 10.4642 10.2048 10.1684C10.043 10.0082 9.96049 9.79753 9.96049 9.58794C9.96049 9.37725 10.043 9.16546 10.207 9.00525L15.0724 4.2395L15.1584 4.16609C15.4873 3.92149 15.9573 3.94706 16.2559 4.24279ZM16.3291 18.6783C16.5802 19.001 16.5535 19.4636 16.256 19.7583C15.9303 20.0798 15.4005 20.0809 15.0725 19.7594L7.74651 12.5828L7.67131 12.4987C7.55732 12.3523 7.5 12.1766 7.5 12.0001C7.5 11.7905 7.58254 11.5798 7.74428 11.4196C8.06998 11.097 8.5998 11.0959 8.92773 11.4174L16.2538 18.594L16.3291 18.6783Z"
                  />
                </svg>
                <svg
                    class="svg2 d-none"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M19 8.5L12 15.5L5 8.5"
                      stroke="#444"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div
                id="allCategory"
                class="sub-all-categories-side-btn text-right collapse"
                aria-labelledby="headingFour"
                data-parent="#accordionExample"
            >
              <div class="card-body pt-1">
                <ul class="all-categories-sub-menu pr-3">
                  <li v-for="cate in category" :key="cate.id"><a @click="gotoCategoryAndClose(cate.id,cate.slug)">{{cate.title}}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-for="item in menu" :key="item.id"  class="card">
          <div class="card-header my-1 pr-3 d-flex justify-content-between" id="headingOne" >
            <a
                v-if="item.link"
                :target="item.new_tab ? '_blank' : '_self'"
                :href="item.link"
                @click="close" class="d-flex my-auto text-color-444  font-weight-bold">
              <span class="circle-list bg-color-theme rounded-circle mt-1 ml-3"></span>
              {{item.title}} </a>
            <router-link v-else @click.native="close"
                         class="d-flex my-auto text-color-444  font-weight-bold"
                         :to="$router.resolve(findUrl(item.linkable_type, item.linkable_id, item.slug, item.link)).href">
              <span class="circle-list bg-color-theme rounded-circle mt-1 ml-3"></span>
              {{item.title}}
            </router-link>
            <button v-show="item.children.length!=0" @click="(e) => rotateArrow(e.currentTarget)"
                    class="all-categories-side-btn with-event collapsed" type="button" data-toggle="collapse"
                    :data-target="'#mobileMenuChild'+item.id" aria-expanded="true" aria-controls="collapseOne" >
              <svg
                  class="svg1"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    stroke="#444"
                    stroke-width="3"
                    d="M16.2559 4.24279C16.5805 4.56431 16.5827 5.08555 16.2537 5.40707L11.3882 10.1717L11.3023 10.2451C10.9734 10.4897 10.5033 10.4642 10.2048 10.1684C10.043 10.0082 9.96049 9.79753 9.96049 9.58794C9.96049 9.37725 10.043 9.16546 10.207 9.00525L15.0724 4.2395L15.1584 4.16609C15.4873 3.92149 15.9573 3.94706 16.2559 4.24279ZM16.3291 18.6783C16.5802 19.001 16.5535 19.4636 16.256 19.7583C15.9303 20.0798 15.4005 20.0809 15.0725 19.7594L7.74651 12.5828L7.67131 12.4987C7.55732 12.3523 7.5 12.1766 7.5 12.0001C7.5 11.7905 7.58254 11.5798 7.74428 11.4196C8.06998 11.097 8.5998 11.0959 8.92773 11.4174L16.2538 18.594L16.3291 18.6783Z"
                />
              </svg>
              <svg
                  class="svg2 d-none"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M19 8.5L12 15.5L5 8.5"
                    stroke="#444"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
              </svg>
            </button>

          </div>

          <div  v-show="item.children.length!=0" :id="'mobileMenuChild'+item.id" class="sub-all-categories-side-btn text-right collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body py-0">
              <ul class="all-categories-sub-menu pr-3">
                <li v-for="child in item.children" :key="child.id">
                  <div class="d-flex">
                    <a v-if="child.link" @click="close" :target="child.new_tab ? '_blank' : '_self'"
                       :href="child.link"
                    >{{child.title}}</a>
                    <router-link v-else @click.native="close"
                                 :target="child.new_tab ? '_blank' : '_self'"
                                 :to="findUrl(child.linkable_type,child.linkable_id, child.slug)">
                      {{child.title}}
                    </router-link>
                    <button v-show="child.children.length!=0" @click="(e) => rotateArrow(e.currentTarget)"
                            class="all-categories-side-btn with-event collapsed" type="button" data-toggle="collapse"
                            :data-target="'#mobileMenuChildChild'+child.id" aria-expanded="true" aria-controls="collapseOne" >
                      <svg
                          class="svg1"
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            stroke="#444"
                            stroke-width="3"
                            d="M16.2559 4.24279C16.5805 4.56431 16.5827 5.08555 16.2537 5.40707L11.3882 10.1717L11.3023 10.2451C10.9734 10.4897 10.5033 10.4642 10.2048 10.1684C10.043 10.0082 9.96049 9.79753 9.96049 9.58794C9.96049 9.37725 10.043 9.16546 10.207 9.00525L15.0724 4.2395L15.1584 4.16609C15.4873 3.92149 15.9573 3.94706 16.2559 4.24279ZM16.3291 18.6783C16.5802 19.001 16.5535 19.4636 16.256 19.7583C15.9303 20.0798 15.4005 20.0809 15.0725 19.7594L7.74651 12.5828L7.67131 12.4987C7.55732 12.3523 7.5 12.1766 7.5 12.0001C7.5 11.7905 7.58254 11.5798 7.74428 11.4196C8.06998 11.097 8.5998 11.0959 8.92773 11.4174L16.2538 18.594L16.3291 18.6783Z"
                        />
                      </svg>
                      <svg
                          class="svg2 d-none"
                          width="15"
                          height="15"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M19 8.5L12 15.5L5 8.5"
                            stroke="#444"
                            stroke-width="4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div  v-show="child.children.length!=0" :id="'mobileMenuChildChild'+child.id" class="sub-all-categories-side-btn text-right collapse" aria-labelledby="headingOne">
                    <div class="card-body py-0">
                      <ul class="all-categories-sub-menu pr-3">
                        <li v-for="childChild in child.children" :key="childChild.id">
                          <a v-if="childChild.link" @click="close" :target="childChild.new_tab ? '_blank' : '_self'"
                             :href="childChild.link"
                          >{{childChild.title}}</a>
                          <router-link v-else @click.native="close"
                                       :target="childChild.new_tab ? '_blank' : '_self'"
                                       :to="findUrl(childChild.linkable_type,childChild.linkable_id, childChild.slug)">
                            {{childChild.title}}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>

                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>

    </section><!--resp menu button-->
    <div class="all-categories-resp" @click="allCategoriesToggle()"></div> <!-- close menu by click to body-->

  </div>

</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import CloseSvg from "@/components/svgs/CloseSvg";

export default {
  name: "RespMenu",
  components: {CloseSvg},
  watch: {

  },
  methods:{
    gotoCategoryAndClose(id,slug){
      this.$router.push({'name':'product.category',params:{'category':id,'slug':slug}})
      this.allCategoriesToggle();
    },
    close(){
      setTimeout(() => {
        let hasClassClose= document.getElementById('all-categories').classList.contains('all-categories-close');
        if (hasClassClose) {
          this.allCategoriesToggle();
        }
      }, 200)
    },
    findUrl,
    allCategoriesToggle(){
      document.getElementById('all-categories').classList.toggle('all-categories-close');
      let hasClassClose= document.getElementById('all-categories').classList.contains('all-categories-close');
      let catSide = document.getElementsByClassName('all-categories-side')[0];
      let allCategoriesResp = document.querySelectorAll('.all-categories-resp')[0];

      // let wrapper = document.getElementsByClassName('wrapper')[0];
      if(hasClassClose){
        window.addEventListener("popstate", this.checkBackButton);
        this.$router.push(this.$route.fullPath + "#resp-menu");
        catSide.style.right="0px";
        allCategoriesResp.style.display="block";
        // if(window.screen.width >= 1200){
        // wrapper.style.paddingRight="250px";
        // }
      }
      else{
        // if(window.screen.width >= 1200){
        //     wrapper.style.paddingRight="0";
        // }
        catSide.style.right="-103%";
        allCategoriesResp.style.display="none";
        this.$router.replace(this.$route.fullPath.replace("#resp-menu", "")).catch(e => {});
        window.removeEventListener("popstate", this.checkBackButton);
      }
    },
    rotateArrow(el) {
      el.children.forEach(child => {
        if(child.classList.contains('d-none')){
          child.classList.remove('d-none');
        }
        else {
          child.classList.add('d-none');
        }
      })
    },
    checkBackButton() {
      this.allCategoriesToggle();
    }
  },
  mounted() {
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.checkBackButton);
  },
  computed:{
    // دریافت منو ها از ویو ایکس
    menu(){
      if(this.$store.getters["front/getHomeData"]==null){
        return this.$store.getters["front/getHomeData"]
      }else{
        return this.$store.getters["front/getHomeData"].menu.header;
      }
    },
    // دریافت دسته بندی ها ازویوایکس
    category(){
      if(this.$store.getters["front/getHomeData"]==null){
        return this.$store.getters["front/getHomeData"]
      }else{

        return this.$store.getters["front/getHomeData"].categories;
      }
    }
  }
}
</script>
<style scoped>
.all-categories-side .all-categories-side-btn {
  flex-grow: 100;
  justify-content: flex-end;
  display: flex;
}


.all-categories-sub-menu > li {
  margin-bottom: 11px;
}
.bgWhite{
  background-color: white;
}
</style>
