<template>
    <div id="review" class="tab-pane fade" v-if="product != null && product.description != null"
        :class="activeTab === 'review' ? 'in active show' :''">
        <div class="fontsize-small text-color-666 text-justify line-height2 p-3" v-html="product.description">
        </div>
    </div>
</template>
<script>
    export default {
        props:['activeTab' , 'product'],
    }
</script>
