<template>
  <div id="amazingDiscount" class="container mt-5">
    <div class="row flex-nowrap inner-container">
      <div class="col-lg-2 d-none d-lg-flex static-slide">
        <div>
          <h6 class="neonText">آنجل بار</h6>
        </div>
        <div class="static-slide-img tada">
          <img
            class="w-100 h-100"
            src="@/assets/images/amazingDiscount.png"
            alt="amazing discount image"
          />
        </div>
        <div><router-link :to="link">مشاهده همه</router-link></div>
      </div>
      <div ref="swiper" class="col-lg-10 swiper">
        <div class=" swiper-wrapper">
          <div class="swiper-slide d-flex d-lg-none static-slide">
            <div>
              <h6 class="neonText">آنجل بار</h6>
            </div>
            <div class="static-slide-img tada">
              <img
                class="w-100 h-100"
                src="@/assets/images/amazingDiscount.png"
                alt="amazing discount image"
              />
            </div>
            <div><router-link :to="link">مشاهده همه</router-link></div>
          </div>
          <Product
            class="swiper-slide"
            v-for="(product, index) in products"
            :key="index"
            :product="product"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
export default {
  name: "AmazingDiscount",
  components: {
    Product,
  },
  data() {
    return {
      swiper: null,
    };
  },
  props: {
    products: Array,
    link: String,
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      slidesPerView: 1,
      spaceBetween: 10,
      loop: false,
      speed: 800,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 2.5,
          spaceBetween: 25,
        },
        1400: {
          slidesPerView: 3.5,
          spaceBetween: 30,
        },
      },
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeFunction);
  },
  computed: {
    showCount() {
      return 16;
    },
  },
  methods: {
    hasAny(name) {
      return this[name] && this[name].length;
    },
    getProductMainImageUrl(product) {
      return getImage(product.major_image, "lg");
    },
    onResize() {
      this.setMaxWidth();
    },
    loadTab(name) {
      if (this.loadedTabs.includes(name)) {
        return;
      }
      this.loadedTabs.push(name);
      this[name].forEach((product) => {
        if (!product) return;
        let url = this.getProductMainImageUrl(product);
        let img = new Image();
        img.src = url;
      });
    },
    setMaxWidth() {
      let slidesPerView = this.getSlidesPerView();
      let spaceBetween = slidesPerView.spaceBetween;
      slidesPerView = slidesPerView.slidesPerView;
      this.maxWidth =
        (document.querySelector("#amazing-container").getBoundingClientRect()
          .width -
          spaceBetween * slidesPerView) /
          slidesPerView +
        (window.innerWidth > 768 ? 2 : 0);
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
    initSlider(kill = false) {
      this.initSwiper("amazing", kill, () => {
        if (this.slider) {
          this.slider.destroy();
        }
        this.slider = new Swiper("#amazing-container .swiper-container", {
          autoplay: false,
          loop: false,
          navigation:false,
          breakpoints:breakpoints
        });
        setTimeout(() => {
          this.minHeight = document
            .querySelector("#amazing-container .swiper-container")
            .getBoundingClientRect().height;
        }, 1700);
      });
    },
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
  

  },
};
</script>
<style scoped>
#amazingDiscount .inner-container {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 2rem 2rem;
  background: rgb(149, 189, 220);
  background: linear-gradient(
    45deg,
    rgba(149, 189, 220, 0.8) 0%,
    rgba(194, 209, 229, 0.8) 22%,
    rgba(254, 121, 61, 0.7) 48%,
    rgba(251, 76, 31, 0.6) 69%,
    rgba(251, 198, 208, 1) 100%
  );
}
#amazingDiscount .swiper {
  overflow: hidden;
}
#amazingDiscount .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
#amazingDiscount a {
  color: #ffffff;
}
#amazingDiscount .static-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-radius: 20px;
  flex-direction: column;
}
#amazingDiscount .neonText {
  text-align: center;
  color: #fff;
  font-size: 2.5rem;
  animation: pulsate 1.5s infinite alternate;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #fb4c1f,
    0 0 82px #fb4c1f, 0 0 92px #fb4c1f, 0 0 102px #fb4c1f, 0 0 151px #fb4c1f;
  font-weight: 500;
}
#amazingDiscount .static-slide-img {
  width: 200px;
}
.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulsate {
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #fb4c1f,
      0 0 80px #fb4c1f, 0 0 90px #fb4c1f, 0 0 100px #fb4c1f, 0 0 150px #fb4c1f;
  }

  0% {
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #fff, 0 0 10px #fb4c1f,
      0 0 45px #fb4c1f, 0 0 55px #fb4c1f, 0 0 70px #fb4c1f, 0 0 80px #fb4c1f;
  }
}
@media (max-width: 1199px) {
  #amazingDiscount .static-slide-img {
    width: 130px;
  }
}
@media (max-width: 1023px) {
  #amazingDiscount .static-slide-img {
    width: 130px;
  }
  #amazingDiscount .neonText {
    font-size: 2rem;
  }
}
@media (max-width: 991px) {
  #amazingDiscount .static-slide-img {
    width: 200px;
  }
}
</style>
